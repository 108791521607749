import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Tower from '../../../_metronic/assets/icons/Tower.svg'
import CarAc from '../../../_metronic/assets/icons/carac.svg'
import CarDc from '../../../_metronic/assets/icons/cardc.svg'
import Distribute from '../../../_metronic/assets/icons/distribute.svg'
import Shop from '../../../_metronic/assets/icons/shop.svg'
import SolarPanel from '../../../_metronic/assets/icons/solarpanel.svg'
import {SVGICON} from '../../../_metronic/helpers'
import SimpleLoader from '../../sharedComponents/Loader/SimpleLoader'
import ArrowComponent from './ArrowComponent'
import BatteryComponent from './BatteryComponent'
import EnergyFlowCard from './EnergyFlowCard'
import LineComponent from './LineComponent'
import MainCircle from './MainCircle'
import SmallCircle from './SmallCircle'
import {EnergyFlowsType} from './types/getEnergyFlowsResponseType'
interface Props {
  displayEnergyFlows: EnergyFlowsType
  loading: boolean
}

const EnergyFlowsMain: FC<Props> = ({displayEnergyFlows, loading}) => {
  const intl = useIntl()
  const [tabletView, setTabletView] = useState<boolean>()
  const [innerWidth, setInnerWidth] = useState<number>(window.innerWidth)
  const [consumer2Exists, setConsumer2Exists] = useState(false)
  const [consumer3Exists, setConsumer3Exists] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 1520) {
      setTabletView(false)
    } else if (window.innerWidth < 1520) {
      setTabletView(true)
    }
  }, [])

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      setInnerWidth(newWidth)
    }

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  useEffect(() => {
    if (displayEnergyFlows?.consumers?.consumer2) {
      setConsumer2Exists(true)
    } else if (displayEnergyFlows?.consumers?.consumer3) {
      setConsumer3Exists(true)
    }
  }, [displayEnergyFlows])

  return (
    <>
      {tabletView || innerWidth <= 1520 ? (
        <div className='card position-relative'>
          {loading ? (
            <SimpleLoader height='480px' />
          ) : (
            <>
              <span
                className='fw-bolder p-2 position-absolute'
                style={{color: '#1E4696', fontSize: '22px', top: '20px', left: '20px'}}
              >
                {intl.formatMessage({id: 'MENU_ENERGYFLOWS_ENERGYINFO_CURRENT'})}
              </span>
              <div className='card-body d-flex justify-content-center'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle gs-0 gy-5 position-relative'
                    style={{minHeight: '480px', minWidth: '860px'}}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th className='p-0 text-center'>
                          <span className='fw-bolder fs-7'>
                            {intl.formatMessage({id: 'MENU_ENERGYFLOWS_ENERGYINFO_PV_SYSTEM'})}
                          </span>
                        </th>
                        <th></th>
                        <th></th>
                        <th
                          className='p-0 text-start position-absolute'
                          style={{
                            top: !consumer3Exists ? '100px' : !consumer2Exists ? '200px' : '0px',
                          }}
                        >
                          <span className='fw-bolder fs-7 pb-2'>
                            {intl.formatMessage({id: 'MENU_ENERGYFLOWS_ENERGYINFO_CONSUMER'})}
                          </span>
                        </th>
                        <th></th>
                        <th></th>
                        <th className='p-0 text-start position-absolute' style={{top: '100px'}}>
                          <span className='fw-bolder fs-7 pb-2'>
                            {intl.formatMessage({
                              id: 'MENU_ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS',
                            })}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/*row-1 start */}
                      <tr className='h-90px'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='d-flex justify-content-center align-items-center'>
                          <div className='position-relative w-70px'>
                            <MainCircle
                              type={'tab1'}
                              src={SolarPanel}
                              className='svg-icon-4x'
                              text={displayEnergyFlows?.pv}
                              percentage={100}
                              maxVoltage={150}
                              voltage={displayEnergyFlows?.pv}
                            />
                            <div
                              className='position-absolute'
                              style={{left: '-25px', top: '120px', transform: 'rotate(90deg)'}}
                            >
                              <ArrowComponent
                                color='#A3C62E'
                                width='120px'
                                type='tab'
                                //@ts-ignore
                                value={displayEnergyFlows?.pv}
                              />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          {consumer3Exists ? (
                            <div className='position-relative d-flex justify-content-center'>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(0.001deg)', top: '50%', left: '10px'}}
                              >
                                <ArrowComponent
                                  width='70px'
                                  type='tab'
                                  value={
                                    consumer3Exists
                                      ? parseInt(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer1?.consumption
                                        )
                                      : 0
                                  }
                                />
                              </div>
                              <SmallCircle
                                size={30}
                                color='#1E4696'
                                value={
                                  consumer3Exists
                                    ? displayEnergyFlows?.consumers?.consumer1?.consumption
                                    : '0'
                                }
                              />
                            </div>
                          ) : null}
                        </td>
                        {consumer3Exists ? (
                          <td
                            className={`border border-bottom-0 border-3 rounded px-4 py-0 w-100px`}
                          >
                            <EnergyFlowCard
                              circletype={'tab2'}
                              src={Shop}
                              value={displayEnergyFlows?.consumers?.consumer1?.consumption}
                              percentage={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? (Math.abs(parseFloat(
                                      //@ts-ignore
                                      displayEnergyFlows?.consumers?.consumer1?.consumption
                                    )) /
                                    Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                    100
                                  : 0
                              }
                              title={displayEnergyFlows?.consumers?.consumer1?.name}
                              solarpercent={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? Math.round(
                                      (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer1?.consumption
                                      )) /
                                        //@ts-ignore
                                        Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                        100
                                    )
                                  : 0
                              }
                            />
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {/*row-1 end */}

                      {/*row-2 start */}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <div className='d-flex align-items-center justify-content-center'>
                            <SmallCircle size={30} value={displayEnergyFlows?.pv} />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          {consumer2Exists ? (
                            <div className='position-relative d-flex justify-content-center'>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(0.001deg)', top: '50%', left: '10px'}}
                              >
                                <ArrowComponent
                                  width='70px'
                                  type='tab'
                                  value={
                                    consumer3Exists
                                      ? parseInt(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer2?.consumption
                                        )
                                      : parseInt(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer1?.consumption
                                        )
                                  }
                                />
                              </div>
                              <SmallCircle
                                size={30}
                                color='#1E4696'
                                value={
                                  consumer3Exists
                                    ? displayEnergyFlows?.consumers?.consumer2?.consumption
                                    : displayEnergyFlows?.consumers?.consumer1?.consumption
                                }
                              />
                            </div>
                          ) : null}
                        </td>

                        {!consumer3Exists ? (
                          <td
                            className={`border border-bottom-0 border-3 rounded ${
                              !consumer3Exists ? 'border-top' : ''
                            } px-4 py-0 w-100px`}
                          >
                            <EnergyFlowCard
                              circletype={'tab2'}
                              src={Shop}
                              value={displayEnergyFlows?.consumers?.consumer1?.consumption}
                              percentage={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? (Math.abs(parseFloat(
                                      //@ts-ignore
                                      displayEnergyFlows?.consumers?.consumer1?.consumption
                                    )) /
                                      //@ts-ignore
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                    100
                                  : 0
                              }
                              title={displayEnergyFlows?.consumers?.consumer1?.name}
                              solarpercent={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? Math.round(
                                      (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer1?.consumption
                                      )) /
                                        //@ts-ignore
                                        Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                        100
                                    )
                                  : 0
                              }
                            />
                          </td>
                        ) : (
                          <td className={`border-start border-end border-3 rounded px-4 py-0`}>
                            {consumer2Exists ? (
                              <EnergyFlowCard
                                circletype={'tab2'}
                                src={Shop}
                                value={displayEnergyFlows?.consumers?.consumer2?.consumption}
                                percentage={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer2?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                      100
                                    : 0
                                }
                                title={displayEnergyFlows?.consumers?.consumer2?.name}
                                solarpercent={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (Math.abs(parseFloat(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer2?.consumption
                                        )) /
                                        Math.abs(parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          ))) *
                                          100
                                      )
                                    : 0
                                }
                              />
                            ) : null}
                          </td>
                        )}
                        <td></td>
                        <td>
                          <div className='w-40px position-relative'>
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', left: '-24px', top: '15px'}}
                            >
                              <ArrowComponent
                                width='67px'
                                type='tab'
                                value={parseInt(
                                  displayEnergyFlows?.chargingStations?.AC1?.consumption
                                )}
                                color={
                                  parseFloat(
                                    displayEnergyFlows?.chargingStations?.AC1?.consumption
                                  ) < 1
                                    ? 'gray'
                                    : '#1E4696'
                                }
                              />
                            </div>
                            <SmallCircle
                              size={30}
                              color={
                                parseFloat(displayEnergyFlows?.chargingStations?.AC1?.consumption) <
                                1
                                  ? 'gray'
                                  : '#1E4696'
                              }
                              value={displayEnergyFlows?.chargingStations?.AC1?.consumption}
                            />
                          </div>
                        </td>

                        <td className='border border-bottom-0 border-3 rounded px-4 pb-2'>
                          <div
                            className=' d-flex align-items-center gap-4'
                            style={{minHeight: '75px'}}
                          >
                            <div className=''>
                              <MainCircle
                                type={'tab2'}
                                src={CarAc}
                                className='svg-icon-2x'
                                text={displayEnergyFlows?.chargingStations?.AC1?.consumption}
                                percentage={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.chargingStations?.AC1?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                      100
                                    : 0
                                }
                              />
                            </div>
                            <div>
                              <span className='fw-bolder fs-8'>
                                {intl.formatMessage({
                                  id: 'MENU_ENERGYFLOWS_ENERGYINFO_AC_CHARGING_STATIONS',
                                })}
                              </span>
                              <div>
                                <span className='fw-bold fs-8'>
                                  {displayEnergyFlows?.chargingStations?.AC1?.consumption &&
                                  parseInt(displayEnergyFlows?.chargingStations?.AC1?.consumption) >
                                    0
                                    ? '0/1'
                                    : '1/1'}{' '}
                                  {intl.formatMessage({
                                    id: 'ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS_FREE_TEXT',
                                  })}
                                </span>
                              </div>
                              <div className='d-flex gap-1 align-items-center'>
                                <SVGICON src={SolarPanel} className='svg-icon-sm' />
                                <span className='fw-bold fs-9 text-success'>
                                  {displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (parseFloat(
                                          displayEnergyFlows?.chargingStations?.AC1?.consumption
                                        ) /
                                          parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          )) *
                                          100
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/*row-2 end */}

                      {/*row-3 start */}
                      <tr className='h-90px'>
                        <td className='d-flex justify-content-end align-items-center'>
                          <div className='position-relative'>
                            <MainCircle
                              type={'tab1'}
                              src={Tower}
                              className='svg-icon-3x'
                              text={displayEnergyFlows?.grid}
                              percentage={100}
                              color='brown'
                              maxVoltage={250}
                              voltage={displayEnergyFlows?.grid}
                            />
                            <span
                              className='fw-bolder fs-7 position-absolute w-100'
                              style={{top: '-24px', left: '22px'}}
                            >
                              {intl.formatMessage({id: 'MENU_ENERGYFLOWS_ENERGYINFO_NETWORK'})}
                            </span>
                            <div
                              className='position-absolute'
                              style={{
                                transform: displayEnergyFlows?.grid.startsWith('-')
                                  ? 'rotate(180deg)'
                                  : 'rotate(0.001deg)',
                                top: '50%',
                                left: displayEnergyFlows?.grid.startsWith('-') ? '110%' : '50%',
                              }}
                            >
                              {displayEnergyFlows?.grid.startsWith('-') ? (
                                <ArrowComponent
                                  width='150px'
                                  type='tab'
                                  color='#A3C62E'
                                  //@ts-ignore
                                  value={displayEnergyFlows?.grid}
                                />
                              ) : (
                                <ArrowComponent
                                  width='150px'
                                  type='tab'
                                  //@ts-ignore
                                  value={displayEnergyFlows?.grid}
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end'>
                            {displayEnergyFlows?.grid.startsWith('-') ? (
                              <SmallCircle size={30} value={displayEnergyFlows?.grid} />
                            ) : (
                              <SmallCircle
                                size={30}
                                value={displayEnergyFlows?.grid}
                                color='#1E4696'
                              />
                            )}
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className='d-flex align-items-center justify-content-center'>
                            <div className='position-relative'>
                              <MainCircle
                                type={'tab3'}
                                src={Distribute}
                                className='svg-icon-2x'
                                text={undefined}
                                percentage={0}
                              />
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(125deg)', right: '15px', top: '90px'}}
                              >
                                <ArrowComponent color='gray' width='140px' type='tab' value={0} />
                              </div>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(90deg)', right: '-52px', top: '120px'}}
                              >
                                <ArrowComponent color='gray' width='155px' type='tab' />
                              </div>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(55deg)', right: '-65px', top: '70px'}}
                              >
                                <ArrowComponent
                                  width='90px'
                                  type='tab'
                                  //@ts-ignore
                                  value={displayEnergyFlows?.totalConsumerConsumption}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className='position-relative d-flex justify-content-center w-80px'>
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', top: '50%', left: '10px'}}
                            >
                              <ArrowComponent
                                width='70px'
                                type='tab'
                                value={
                                  consumer3Exists
                                    ? parseInt(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer3?.consumption
                                      )
                                    : consumer2Exists
                                    ? parseInt(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer2?.consumption
                                      )
                                    : parseInt(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer1?.consumption
                                      )
                                }
                              />
                            </div>
                            <SmallCircle
                              size={30}
                              color='#1E4696'
                              value={
                                consumer3Exists
                                  ? displayEnergyFlows?.consumers?.consumer3?.consumption
                                  : consumer2Exists
                                  ? displayEnergyFlows?.consumers?.consumer2?.consumption
                                  : displayEnergyFlows?.consumers?.consumer1?.consumption
                              }
                            />
                          </div>
                        </td>

                        {consumer3Exists ? (
                          <td
                            className={`${
                              consumer3Exists ? 'border border-top-0 border-3 rounded' : ''
                            } px-4 py-0`}
                          >
                            <EnergyFlowCard
                              circletype={'tab2'}
                              src={Shop}
                              value={displayEnergyFlows?.consumers?.consumer3?.consumption}
                              percentage={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? (Math.abs(parseFloat(
                                      //@ts-ignore
                                      displayEnergyFlows?.consumers?.consumer3?.consumption
                                    )) /
                                    Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                    100
                                  : 0
                              }
                              title={displayEnergyFlows?.consumers?.consumer3?.name}
                              solarpercent={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? Math.round(
                                      (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer3?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                        100
                                    )
                                  : 0
                              }
                            />
                          </td>
                        ) : (
                          <td
                            className={`${
                              consumer2Exists
                                ? 'border-start border-end border-3 rounded'
                                : 'border'
                            } ${!consumer3Exists ? 'border-bottom' : ''} px-4 py-0`}
                          >
                            {consumer2Exists ? (
                              <EnergyFlowCard
                                circletype={'tab2'}
                                src={Shop}
                                value={displayEnergyFlows?.consumers?.consumer2?.consumption}
                                percentage={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer2?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                      100
                                    : 0
                                }
                                title={displayEnergyFlows?.consumers?.consumer2?.name}
                                solarpercent={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (Math.abs(parseFloat(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer2?.consumption
                                        )) /
                                        Math.abs(parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          ))) *
                                          100
                                      )
                                    : 0
                                }
                              />
                            ) : (
                              <EnergyFlowCard
                                circletype={'tab2'}
                                src={Shop}
                                value={displayEnergyFlows?.consumers?.consumer1?.consumption}
                                percentage={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer1?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                      100
                                    : 0
                                }
                                title={displayEnergyFlows?.consumers?.consumer1?.name}
                                solarpercent={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (Math.abs(parseFloat(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer1?.consumption
                                        )) /
                                        Math.abs(parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          ))) *
                                          100
                                      )
                                    : 0
                                }
                              />
                            )}
                          </td>
                        )}
                        <td></td>
                        <td>
                          <div className='w-40px position-relative'>
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', left: '-24px', top: '15px'}}
                            >
                              <ArrowComponent
                                width='67px'
                                type='tab'
                                value={parseInt(
                                  displayEnergyFlows?.chargingStations?.DC1?.consumption
                                )}
                                color={
                                  parseFloat(
                                    displayEnergyFlows?.chargingStations?.DC1?.consumption
                                  ) < 1
                                    ? 'gray'
                                    : '#1E4696'
                                }
                              />
                            </div>
                            <SmallCircle
                              size={30}
                              color={
                                parseFloat(displayEnergyFlows?.chargingStations?.DC1?.consumption) <
                                1
                                  ? 'gray'
                                  : '#1E4696'
                              }
                              value={displayEnergyFlows?.chargingStations?.DC1?.consumption}
                            />
                          </div>
                        </td>
                        <td
                          className='border border-top-0 border-3 rounded px-4 py-0'
                          style={{width: '200px'}}
                        >
                          <div
                            className=' d-flex align-items-center gap-4'
                            style={{minHeight: '75px'}}
                          >
                            <div className=''>
                              <MainCircle
                                type={'tab2'}
                                src={CarDc}
                                className='svg-icon-2x'
                                text={displayEnergyFlows?.chargingStations?.DC1?.consumption}
                                percentage={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? //@ts-ignore
                                      (Math.abs(parseFloat(
                                        displayEnergyFlows?.chargingStations?.DC1?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                      100
                                    : 0
                                }
                              />
                            </div>
                            <div>
                              <span className='fw-bolder fs-8'>
                                {intl.formatMessage({
                                  id: 'MENU_ENERGYFLOWS_ENERGYINFO_DC_CHARGING_STATIONS',
                                })}
                              </span>
                              <div>
                                <span className='fw-bold fs-8'>
                                  {displayEnergyFlows?.chargingStations?.DC1?.consumption &&
                                  parseInt(displayEnergyFlows?.chargingStations?.DC1?.consumption) >
                                    0
                                    ? '0/1'
                                    : '1/1'}{' '}
                                  {intl.formatMessage({
                                    id: 'ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS_FREE_TEXT',
                                  })}
                                </span>
                              </div>
                              <div className='d-flex gap-1 align-items-center'>
                                <SVGICON src={SolarPanel} className='svg-icon-sm' />
                                <span className='fw-bold fs-9 text-success'>
                                  {displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (parseFloat(
                                          displayEnergyFlows?.chargingStations?.DC1?.consumption
                                        ) /
                                          parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          )) *
                                          100
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/*row-3 end */}

                      {/*row-4 start */}
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <SmallCircle size={30} value='0' />
                        </td>
                        <td></td>
                        <td>
                          <div className='position-relative'>
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', top: '50%', left: '56%'}}
                            >
                              <div className='position-relative'>
                                <LineComponent
                                  width={`${innerWidth < 993 ? '276px' : '280px'}`}
                                  type='tab'
                                />
                                <div
                                  className='position-absolute'
                                  style={{
                                    transform: 'rotate(270deg)',
                                    bottom: '86px',
                                    right: '-85px',
                                  }}
                                >
                                  <div className='position-relative'>
                                    <LineComponent width='172px' type='tab' />
                                  </div>
                                </div>
                                <div
                                  className='position-absolute'
                                  style={{
                                    transform: 'rotate(270deg)',
                                    bottom: consumer3Exists
                                      ? '133px'
                                      : consumer2Exists
                                      ? '86px'
                                      : '38px',
                                    left: consumer3Exists
                                      ? '-100px'
                                      : consumer2Exists
                                      ? '-52px'
                                      : '-5px',
                                  }}
                                >
                                  <LineComponent
                                    width={
                                      consumer3Exists ? '267px' : consumer2Exists ? '172px' : '78px'
                                    }
                                    type='tab'
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='w-10px'>
                              <SmallCircle
                                size={30}
                                color='#1E4696'
                                value={displayEnergyFlows?.totalConsumerConsumption}
                              />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {/*row-4 end */}

                      {/*row-5 start */}
                      <tr>
                        <td></td>
                        <td>
                          <div className='position-relative w-20px'>
                            {/* <svg
                        width='6em'
                        height='6em'
                        viewBox='0 0 77 41'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          x='1'
                          y='1'
                          width='71'
                          height='39'
                          rx='1'
                          stroke='#DBDBDB'
                          strokeWidth='2'
                        />

                        <path
                          d='M73 15H74C75.1046 15 76 15.8954 76 17V20.5V24C76 25.1046 75.1046 26 74 26H73'
                          stroke='#DBDBDB'
                          strokeWidth='2'
                        />

                        <rect
                          x='3'
                          y='3'
                          width={`${75 / 1.15}%`}
                          height='35'
                          rx='1'
                          fill='#F5C451'
                        />
                        <text
                          x='25'
                          y='25'
                          style={{fontSize: '14px'}}
                          className='fw-bolder'
                          fill='black'
                        >
                          75%
                        </text>
                      </svg>

                      <div
                        className='d-flex flex-column align-items-center position-absolute'
                        style={{bottom: '-14px'}}
                      >
                        <span className='fw-bolder fs-9 w-90px'>
                          {intl.formatMessage({
                            id: 'MENU_ENERGYFLOWS_ENERGYINFO_EXTERNAL_STORAGE',
                          })}
                        </span>
                        <span className='fw-bold fs-9'>370/402 kWh</span>
                      </div> */}
                            <BatteryComponent
                              text={intl.formatMessage({
                                id: 'MENU_ENERGYFLOWS_ENERGYINFO_EXTERNAL_STORAGE',
                              })}
                              percentage={97}
                              value='390/402'
                              type='tab'
                            />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className='d-flex align-items-center justify-content-center position-relative'>
                            <div className='position-relative'>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(0.001deg)', top: '50%', left: '95%'}}
                              >
                                <ArrowComponent color='gray' width='160px' type='tab' value={0} />
                              </div>
                              <SmallCircle size={30} value='0' />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <div className='position-relative'>
                            {/* <svg
                        width='6em'
                        height='6em'
                        viewBox='0 0 77 41'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          x='1'
                          y='1'
                          width='71'
                          height='39'
                          rx='1'
                          stroke='#DBDBDB'
                          strokeWidth='2'
                        />

                        <path
                          d='M73 15H74C75.1046 15 76 15.8954 76 17V20.5V24C76 25.1046 75.1046 26 74 26H73'
                          stroke='#DBDBDB'
                          strokeWidth='2'
                        />

                        <rect
                          x='3'
                          y='3'
                          width={`${92 / 1.15}%`}
                          height='35'
                          rx='1'
                          fill='#F5C451'
                        />
                        <text
                          x='25'
                          y='25'
                          style={{fontSize: '14px'}}
                          className='fw-bolder'
                          fill='black'
                        >
                          92%
                        </text>
                      </svg>
                      <div
                        className='d-flex flex-column align-items-center position-absolute px-2'
                        style={{bottom: '-14px', left: '-5px'}}
                      >
                        <span className='fw-bolder fs-9'>
                          {intl.formatMessage({
                            id: 'MENU_ENERGYFLOWS_ENERGYINFO_ENERGY_STORAGE',
                          })}
                        </span>
                        <span className='fw-bold fs-9'>370/402 kWh</span>
                      </div> */}
                            <BatteryComponent
                              text={intl.formatMessage({
                                id: 'MENU_ENERGYFLOWS_ENERGYINFO_ENERGY_STORAGE',
                              })}
                              percentage={92}
                              value='370/402'
                              type='tab'
                            />
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', top: '50%', left: '85px'}}
                            >
                              <ArrowComponent color='gray' value={0} width='138px' type='tab' />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='w-10px'>
                            <SmallCircle size={30} value='0' />
                          </div>
                        </td>
                        <td></td>
                        <td className='border border-3 rounded px-4 py-0'>
                          <div className=' d-flex align-items-center gap-4'>
                            <div className=''>
                              <MainCircle
                                type={'tab2'}
                                src={CarDc}
                                className='svg-icon-2x'
                                text='0'
                                percentage={0}
                              />
                            </div>
                            <div>
                              <span className='fw-bolder fs-8'>
                                {intl.formatMessage({
                                  id: 'MENU_ENERGYFLOWS_ENERGYINFO_BATTERY_BUFFERED_FAST_CHARGING_STATION',
                                })}
                              </span>
                              <div>
                                <span className='fw-bold fs-8'>
                                  {displayEnergyFlows?.chargingStations?.AC1?.consumption
                                    ? '1/1'
                                    : '0/1'}{' '}
                                  {intl.formatMessage({
                                    id: 'ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS_FREE_TEXT',
                                  })}
                                </span>
                              </div>
                              <div className='d-flex gap-1 align-items-center'>
                                <SVGICON src={SolarPanel} className='svg-icon-sm' />
                                <span className='fw-bold fs-9 text-success'>0%</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/*row-5 end */}
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className='card position-relative'>
          {loading ? (
            <SimpleLoader height='700px' />
          ) : (
            <>
              <span
                className='fw-bolder p-2 position-absolute'
                style={{color: '#1E4696', fontSize: '22px', top: '20px', left: '20px'}}
              >
                {intl.formatMessage({id: 'MENU_ENERGYFLOWS_ENERGYINFO_CURRENT'})}
              </span>
              <div className='card-body d-flex justify-content-center'>
                <div className='table-responsive'>
                  <table
                    className='table align-middle gs-0 gy-5 position-relative'
                    style={{width: '1130px'}}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th className='p-0 text-center'>
                          <span className='fw-bolder fs-6'>
                            {intl.formatMessage({id: 'MENU_ENERGYFLOWS_ENERGYINFO_PV_SYSTEM'})}
                          </span>
                        </th>
                        <th></th>
                        <th></th>
                        <th
                          className='p-0 text-start position-absolute'
                          style={{
                            top: !consumer3Exists ? '110px' : !consumer2Exists ? '210px' : '0px',
                          }}
                        >
                          <span className='fw-bolder fs-6 pb-2'>
                            {intl.formatMessage({id: 'MENU_ENERGYFLOWS_ENERGYINFO_CONSUMER'})}
                          </span>
                        </th>
                        <th></th>
                        <th></th>
                        <th className='p-0 text-start position-absolute' style={{top: '110px'}}>
                          <span className='fw-bolder fs-6 pb-2'>
                            {intl.formatMessage({
                              id: 'MENU_ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS',
                            })}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/*row-1 start */}
                      <tr className='h-100px'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className='d-flex justify-content-center align-items-center w-90px'>
                          <div className='position-relative'>
                            <MainCircle
                              type={1}
                              src={SolarPanel}
                              className='svg-icon-4x'
                              text={displayEnergyFlows?.pv}
                              percentage={100}
                              maxVoltage={150}
                              voltage={displayEnergyFlows?.pv}
                            />
                            <div
                              className='position-absolute'
                              style={{left: '-16px', top: '150px', transform: 'rotate(90deg)'}}
                            >
                              <ArrowComponent
                                color='#A3C62E'
                                width='120px'
                                //@ts-ignore
                                value={displayEnergyFlows?.pv}
                              />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          {consumer3Exists ? (
                            <div className='position-relative d-flex justify-content-center'>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(0.001deg)', top: '50%', left: '-3px'}}
                              >
                                <ArrowComponent
                                  width='120px'
                                  value={
                                    consumer3Exists
                                      ? parseInt(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer1?.consumption
                                        )
                                      : 0
                                  }
                                />
                              </div>
                              <SmallCircle
                                size={50}
                                color='#1E4696'
                                value={
                                  consumer3Exists
                                    ? displayEnergyFlows?.consumers?.consumer1?.consumption
                                    : '0'
                                }
                              />
                            </div>
                          ) : null}
                        </td>

                        {consumer3Exists ? (
                          <td
                            className={`${
                              consumer3Exists ? 'border border-bottom-0 border-3 rounded' : ''
                            } px-4 py-0`}
                          >
                            <EnergyFlowCard
                              circletype={2}
                              src={Shop}
                              value={displayEnergyFlows?.consumers?.consumer1?.consumption}
                              percentage={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? (Math.abs(parseFloat(
                                      //@ts-ignore
                                      displayEnergyFlows?.consumers?.consumer1?.consumption
                                    )) /
                                    Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                    100
                                  : 0
                              }
                              title={displayEnergyFlows?.consumers?.consumer1?.name}
                              solarpercent={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? Math.round(
                                      (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer1?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                        100
                                    )
                                  : 0
                              }
                            />
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {/*row-1 end */}

                      {/*row-2 start */}
                      <tr className='h-100px'>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className=''>
                          <div className='d-flex align-items-center justify-content-center'>
                            <SmallCircle size={50} value={displayEnergyFlows?.pv} />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          {consumer2Exists ? (
                            <div className='position-relative d-flex justify-content-center'>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(0.001deg)', top: '50%', left: '-3px'}}
                              >
                                <ArrowComponent
                                  width='120px'
                                  value={
                                    consumer3Exists
                                      ? parseInt(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer2?.consumption
                                        )
                                      : parseInt(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer1?.consumption
                                        )
                                  }
                                />
                              </div>
                              <SmallCircle
                                size={50}
                                color='#1E4696'
                                value={
                                  consumer3Exists
                                    ? displayEnergyFlows?.consumers?.consumer2?.consumption
                                    : displayEnergyFlows?.consumers?.consumer1?.consumption
                                }
                              />
                            </div>
                          ) : null}
                        </td>
                        {consumer3Exists ? (
                          <td
                            className={`${
                              consumer2Exists ? 'border-start border-end border-3 rounded' : ''
                            } px-4 py-0`}
                          >
                            <EnergyFlowCard
                              circletype={2}
                              src={Shop}
                              value={displayEnergyFlows?.consumers?.consumer2?.consumption}
                              percentage={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? (Math.abs(parseFloat(
                                      //@ts-ignore
                                      displayEnergyFlows?.consumers?.consumer2?.consumption
                                    )) /
                                    Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                    100
                                  : 0
                              }
                              title={displayEnergyFlows?.consumers?.consumer2?.name}
                              solarpercent={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? Math.round(
                                      (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer2?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                        100
                                    )
                                  : 0
                              }
                            />
                          </td>
                        ) : (
                          <td
                            className={`${
                              consumer2Exists ? 'border border-bottom-0 border-3 rounded' : ''
                            }  px-4 py-0`}
                          >
                            <EnergyFlowCard
                              circletype={2}
                              src={Shop}
                              value={displayEnergyFlows?.consumers?.consumer1?.consumption}
                              percentage={
                                displayEnergyFlows?.totalConsumerConsumption
                                //@ts-ignore
                                  ? (Math.abs(parseFloat(displayEnergyFlows?.consumers?.consumer1?.consumption
                                    )) /
                                    Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                    100
                                  : 0
                              }
                              title={displayEnergyFlows?.consumers?.consumer1?.name}
                              solarpercent={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? Math.round(
                                      (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer1?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                        100
                                    )
                                  : 0
                              }
                            />
                          </td>
                        )}
                        <td></td>
                        <td className='w-100px'>
                          <div className='position-relative'>
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', left: '-37px', top: '25px'}}
                            >
                              <ArrowComponent
                                width='110px'
                                value={parseInt(
                                  displayEnergyFlows?.chargingStations?.AC1?.consumption
                                )}
                                color={
                                  parseFloat(
                                    displayEnergyFlows?.chargingStations?.AC1?.consumption
                                  ) < 1
                                    ? 'gray'
                                    : '#1E4696'
                                }
                              />
                            </div>
                            <SmallCircle
                              size={50}
                              color={
                                parseFloat(displayEnergyFlows?.chargingStations?.AC1?.consumption) <
                                1
                                  ? 'gray'
                                  : '#1E4696'
                              }
                              value={displayEnergyFlows?.chargingStations?.AC1?.consumption}
                            />
                          </div>
                        </td>

                        <td className='border border-bottom-0 border-3 rounded px-4 pb-4'>
                          <div className=' d-flex align-items-center gap-4'>
                            <div className=''>
                              <MainCircle
                                type={2}
                                src={CarAc}
                                className='svg-icon-3x'
                                text={displayEnergyFlows?.chargingStations?.AC1?.consumption}
                                percentage={0}
                              />
                            </div>
                            <div>
                              <span className='fw-bolder fs-7'>
                                {intl.formatMessage({
                                  id: 'MENU_ENERGYFLOWS_ENERGYINFO_AC_CHARGING_STATIONS',
                                })}
                              </span>
                              <div>
                                <span className='fw-bold fs-7'>
                                  {displayEnergyFlows?.chargingStations?.AC1?.consumption &&
                                  parseInt(displayEnergyFlows?.chargingStations?.AC1?.consumption) >
                                    0
                                    ? '0/1'
                                    : '1/1'}{' '}
                                  {intl.formatMessage({
                                    id: 'ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS_FREE_TEXT',
                                  })}
                                </span>
                              </div>
                              <div className='d-flex gap-1 align-items-center'>
                                <SVGICON src={SolarPanel} className='svg-icon-sm' />
                                <span className='fw-bold fs-8 text-success'>
                                  {displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (parseFloat(
                                          displayEnergyFlows?.chargingStations?.AC1?.consumption
                                        ) /
                                          parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          )) *
                                          100
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/*row-2 end */}

                      {/*row-3 start */}
                      <tr className='h-100px'>
                        <td className='d-flex justify-content-start align-items-center w-60px'>
                          <div className='position-relative'>
                            <MainCircle
                              type={1}
                              src={Tower}
                              className='svg-icon-3x'
                              text={displayEnergyFlows?.grid}
                              percentage={100}
                              color='brown'
                              maxVoltage={250}
                              voltage={displayEnergyFlows?.grid}
                            />
                            <div
                              className='fw-bolder fs-6 position-absolute'
                              style={{top: '-24px', left: '25px'}}
                            >
                              {intl.formatMessage({id: 'MENU_ENERGYFLOWS_ENERGYINFO_NETWORK'})}
                            </div>
                            <div
                              className='position-absolute'
                              style={{
                                transform: displayEnergyFlows?.grid.startsWith('-')
                                  ? 'rotate(180deg)'
                                  : 'rotate(0.001deg)',
                                top: '50%',
                                left: displayEnergyFlows?.grid.startsWith('-') ? '110%' : '50%',
                              }}
                            >
                              {displayEnergyFlows?.grid.startsWith('-') ? (
                                <ArrowComponent
                                  width='190px'
                                  color='#A3C62E'
                                  //@ts-ignore
                                  value={displayEnergyFlows?.grid}
                                />
                              ) : (
                                <ArrowComponent
                                  width='190px'
                                  //@ts-ignore
                                  value={displayEnergyFlows?.grid}
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td className='w-30px'>
                          <div className='d-flex justify-content-end'>
                            {displayEnergyFlows?.grid.startsWith('-') ? (
                              <SmallCircle size={50} value={displayEnergyFlows?.grid} />
                            ) : (
                              <SmallCircle
                                size={50}
                                value={displayEnergyFlows?.grid}
                                color='#1E4696'
                              />
                            )}
                          </div>
                        </td>
                        <td></td>
                        <td className='w-60px'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <div className='position-relative'>
                              <MainCircle
                                type={3}
                                src={Distribute}
                                className='svg-icon-2x'
                                text={undefined}
                                percentage={0}
                              />
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(120deg)', right: '-10px', top: '120px'}}
                              >
                                <ArrowComponent color='gray' width='190px' value={0} />
                              </div>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(90deg)', right: '-68px', top: '150px'}}
                              >
                                <ArrowComponent color='gray' width='200px' />
                              </div>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(50deg)', right: '-68px', top: '100px'}}
                              >
                                <ArrowComponent
                                  width='100px'
                                  //@ts-ignore
                                  value={displayEnergyFlows?.totalConsumerConsumption}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div
                            className='position-relative d-flex justify-content-center'
                            style={{width: '120px'}}
                          >
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', top: '50%', left: '-3px'}}
                            >
                              <ArrowComponent
                                width='120px'
                                value={
                                  consumer3Exists
                                    ? parseInt(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer3?.consumption
                                      )
                                    : consumer2Exists
                                    ? parseInt(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer2?.consumption
                                      )
                                    : parseInt(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer1?.consumption
                                      )
                                }
                              />
                            </div>
                            <SmallCircle
                              color='#1E4696'
                              size={50}
                              value={
                                consumer3Exists
                                  ? displayEnergyFlows?.consumers?.consumer3?.consumption
                                  : consumer2Exists
                                  ? displayEnergyFlows?.consumers?.consumer2?.consumption
                                  : displayEnergyFlows?.consumers?.consumer1?.consumption
                              }
                            />
                          </div>
                        </td>
                        {consumer3Exists ? (
                          <td
                            className={`border-bottom border-start border-end border-3 rounded ${
                              consumer2Exists ? 'border-top-0' : ''
                            } px-4 py-0`}
                            style={{width: '220px'}}
                          >
                            <EnergyFlowCard
                              circletype={2}
                              src={Shop}
                              value={displayEnergyFlows?.consumers?.consumer3?.consumption}
                              percentage={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? (Math.abs(parseFloat(
                                      //@ts-ignore
                                      displayEnergyFlows?.consumers?.consumer3?.consumption
                                    )) /
                                    Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                    100
                                  : 0
                              }
                              title={displayEnergyFlows?.consumers?.consumer3?.name}
                              solarpercent={
                                displayEnergyFlows?.totalConsumerConsumption
                                  ? Math.round(
                                      (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer3?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                        100
                                    )
                                  : 0
                              }
                            />
                          </td>
                        ) : (
                          <td
                            className={`border-bottom border-start border-end border-3 rounded ${
                              !consumer2Exists ? 'border-top' : ''
                            } px-4 py-0`}
                            style={{width: '220px'}}
                          >
                            {consumer2Exists ? (
                              <EnergyFlowCard
                                circletype={2}
                                src={Shop}
                                value={displayEnergyFlows?.consumers?.consumer2?.consumption}
                                percentage={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer2?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                      100
                                    : 0
                                }
                                title={displayEnergyFlows?.consumers?.consumer2?.name}
                                solarpercent={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (Math.abs(parseFloat(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer2?.consumption
                                        )) /
                                        Math.abs(parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          ))) *
                                          100
                                      )
                                    : 0
                                }
                              />
                            ) : (
                              <EnergyFlowCard
                                circletype={2}
                                src={Shop}
                                value={displayEnergyFlows?.consumers?.consumer1?.consumption}
                                percentage={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? (Math.abs(parseFloat(
                                        //@ts-ignore
                                        displayEnergyFlows?.consumers?.consumer1?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                      100
                                    : 0
                                }
                                title={displayEnergyFlows?.consumers?.consumer1?.name}
                                solarpercent={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (Math.abs(parseFloat(
                                          //@ts-ignore
                                          displayEnergyFlows?.consumers?.consumer1?.consumption
                                        )) /
                                        Math.abs(parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          ))) *
                                          100
                                      )
                                    : 0
                                }
                              />
                            )}
                          </td>
                        )}

                        <td></td>
                        <td>
                          <div className='w-40px position-relative'>
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', left: '-37px', top: '25px'}}
                            >
                              <ArrowComponent
                                width='110px'
                                color={
                                  parseFloat(
                                    displayEnergyFlows?.chargingStations?.DC1?.consumption
                                  ) < 1
                                    ? 'gray'
                                    : '#1E4696'
                                }
                                value={parseInt(
                                  displayEnergyFlows?.chargingStations?.DC1?.consumption
                                )}
                              />
                            </div>
                            <SmallCircle
                              size={50}
                              color={
                                parseFloat(displayEnergyFlows?.chargingStations?.DC1?.consumption) <
                                1
                                  ? 'gray'
                                  : '#1E4696'
                              }
                              value={displayEnergyFlows?.chargingStations?.DC1?.consumption}
                            />
                          </div>
                        </td>
                        <td className='border border-top-0 border-3 rounded px-4 py-0'>
                          <div
                            className=' d-flex align-items-center gap-4'
                            style={{minWidth: '100px'}}
                          >
                            <div className=''>
                              <MainCircle
                                type={2}
                                src={CarDc}
                                className='svg-icon-3x'
                                text={displayEnergyFlows?.chargingStations?.DC1?.consumption}
                                percentage={
                                  displayEnergyFlows?.totalConsumerConsumption
                                    ? //@ts-ignore
                                      (Math.abs(parseFloat(
                                        displayEnergyFlows?.chargingStations?.DC1?.consumption
                                      )) /
                                      Math.abs(parseFloat(displayEnergyFlows?.totalConsumerConsumption))) *
                                      100
                                    : 0
                                }
                              />
                            </div>
                            <div>
                              <span className='fw-bolder fs-7'>
                                {intl.formatMessage({
                                  id: 'MENU_ENERGYFLOWS_ENERGYINFO_DC_CHARGING_STATIONS',
                                })}
                              </span>
                              <div>
                                <span className='fw-bold fs-7'>
                                  {displayEnergyFlows?.chargingStations?.DC1?.consumption &&
                                  parseInt(displayEnergyFlows?.chargingStations?.DC1?.consumption) >
                                    0
                                    ? '0/1'
                                    : '1/1'}{' '}
                                  {intl.formatMessage({
                                    id: 'ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS_FREE_TEXT',
                                  })}
                                </span>
                              </div>
                              <div className='d-flex gap-1 align-items-center'>
                                <SVGICON src={SolarPanel} className='svg-icon-sm' />
                                <span className='fw-bold fs-8 text-success'>
                                  {displayEnergyFlows?.totalConsumerConsumption
                                    ? Math.round(
                                        (parseFloat(
                                          displayEnergyFlows?.chargingStations?.DC1?.consumption
                                        ) /
                                          parseFloat(
                                            displayEnergyFlows?.totalConsumerConsumption
                                          )) *
                                          100
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/*row-3 end */}

                      {/*row-4 start */}
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <div className='w-30px'>
                            <SmallCircle size={50} value='0' />
                          </div>
                        </td>
                        <td></td>
                        <td className='w-30px'>
                          <div className='position-relative'>
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', top: '50%', left: '56%'}}
                            >
                              <div className='position-relative'>
                                <LineComponent width='414px' />
                                <div
                                  className='position-absolute'
                                  style={{
                                    transform: 'rotate(270deg)',
                                    bottom: '97px',
                                    right: '-98px',
                                  }}
                                >
                                  <div className='position-relative'>
                                    <LineComponent width='196px' />
                                  </div>
                                </div>
                                <div
                                  className='position-absolute'
                                  style={{
                                    transform: 'rotate(270deg)',
                                    bottom: consumer3Exists
                                      ? '148px'
                                      : consumer2Exists
                                      ? '97px'
                                      : '46px',
                                    left: consumer3Exists
                                      ? '-112px'
                                      : consumer2Exists
                                      ? '-60px'
                                      : '-8px',
                                  }}
                                >
                                  <LineComponent
                                    width={
                                      consumer3Exists ? '299px' : consumer2Exists ? '196px' : '92px'
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className=''>
                              <SmallCircle
                                size={50}
                                color='#1E4696'
                                value={displayEnergyFlows?.totalConsumerConsumption}
                              />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {/*row-4 end */}

                      {/*row-5 start */}
                      <tr>
                        <td></td>
                        <td>
                          <div className='position-relative d-flex justify-content-center'>
                            <BatteryComponent
                              percentage={97}
                              text={intl.formatMessage({
                                id: 'MENU_ENERGYFLOWS_ENERGYINFO_EXTERNAL_STORAGE',
                              })}
                              value='390/402'
                            />
                          </div>
                        </td>
                        <td></td>
                        <td>
                          <div className='d-flex align-items-center justify-content-center position-relative'>
                            <div className='position-relative'>
                              <div
                                className='position-absolute'
                                style={{transform: 'rotate(0.001deg)', top: '50%', left: '95%'}}
                              >
                                <ArrowComponent color='gray' width='230px' value={0} />
                              </div>
                              <SmallCircle size={50} value='0' />
                            </div>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <div className='position-relative'>
                            <BatteryComponent
                              percentage={92}
                              text={intl.formatMessage({
                                id: 'MENU_ENERGYFLOWS_ENERGYINFO_ENERGY_STORAGE',
                              })}
                              value='370/402'
                            />
                            <div
                              className='position-absolute'
                              style={{transform: 'rotate(0.001deg)', top: '50%', left: '120px'}}
                            >
                              <ArrowComponent color='gray' width='225px' value={0} />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='w-30px'>
                            <SmallCircle size={50} value='0' />
                          </div>
                        </td>
                        <td></td>
                        <td className='border border-3 rounded px-4 py-0'>
                          <div
                            className=' d-flex align-items-center gap-4'
                            style={{minWidth: '100px'}}
                          >
                            <div className=''>
                              <MainCircle
                                type={2}
                                src={CarDc}
                                className='svg-icon-3x'
                                text='0'
                                percentage={0}
                              />
                            </div>
                            <div>
                              <span className='fw-bolder fs-7'>
                                {intl.formatMessage({
                                  id: 'MENU_ENERGYFLOWS_ENERGYINFO_BATTERY_BUFFERED_FAST_CHARGING_STATION',
                                })}
                              </span>
                              <div>
                                <span className='fw-bold fs-7'>
                                  {displayEnergyFlows?.chargingStations?.AC1?.consumption
                                    ? '1/1'
                                    : '0/1'}{' '}
                                  {intl.formatMessage({
                                    id: 'ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS_FREE_TEXT',
                                  })}
                                </span>
                              </div>
                              <div className='d-flex gap-1 align-items-center'>
                                <SVGICON src={SolarPanel} className='svg-icon-sm' />
                                <span className='fw-bold fs-8 text-success'>0%</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/*row-5 end */}

                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default EnergyFlowsMain
