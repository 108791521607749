// import {FC, Suspense} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
// import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
// import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
// import {WithChildren} from '../../_metronic/helpers'
import EnergyFlowsPage from '../pages/energyflows/EnergyFlowsPage'
import {Suspense} from 'react'
import {FallbackView} from '../../_metronic/partials/content/fallback-view/FallbackView'
import {ErrorsPage} from '../modules/errors/ErrorsPage'

export function PrivateRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* Pages */}
        <Route path='/energyflows' component={EnergyFlowsPage} />
        {/* Lazy Modules */}
        {/* Page Not Found */}
        <Redirect from='/' exact={true} to='/energyflows' />
        <Redirect from='/auth/*' to='/energyflows' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}

// const SuspensedView: FC<WithChildren> = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }
