import clsx from 'clsx'
import React, {FC} from 'react'

// styles
import './Loader.scss'

interface Props {
  fullLoader?: boolean
  height?:string
}

const SimpleLoader: FC<Props> = ({fullLoader,height}) => {
  return (
    <>
      <div
      style={{minHeight:height}}
        className={clsx(
          'd-flex flex-row align-items-center justify-content-center p-10',
          fullLoader ? 'spinner-centre' : ''
        )}
      >
        <div
          className={clsx(
            'spinner-grow text-primary me-1',
            fullLoader ? 'spinner-size-md' : 'spinner-size-sm'
          )}
          role='status'
        >
          <span className='sr-only'>Loading...</span>
        </div>
        <div
          className={clsx(
            'spinner-grow text-primary me-1',
            fullLoader ? 'spinner-size-md' : 'spinner-size-sm'
          )}
          role='status'
        >
          <span className='sr-only'>Loading...</span>
        </div>
        <div
          className={clsx(
            'spinner-grow text-primary me-1',
            fullLoader ? 'spinner-size-md' : 'spinner-size-sm'
          )}
          role='status'
        >
          <span className='sr-only'>Loading...</span>
        </div>
        <div
          className={clsx(
            'spinner-grow text-primary',
            fullLoader ? 'spinner-size-md' : 'spinner-size-sm'
          )}
          role='status'
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    </>
  )
}

export default SimpleLoader
