import {FC} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import EnergyFlowsPage from '../pages/energyflows/EnergyFlowsPage'

const Routes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <>
      <Switch>
        <Route path='/error/*' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        {!currentUser ? (
          <Route>
            <AuthPage />
          </Route>
        ) : (
          <>
            <MasterLayout>
              <PrivateRoutes />
            </MasterLayout>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
