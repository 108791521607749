import React, { FC } from 'react'

interface Props {
    percentage:number
    text:any
    value:string
    type?:string

}

const BatteryComponent: FC<Props> = ({percentage, text, value, type}) => {
  return (
    <>
      <svg
        width={type === 'tab' ? '6em' :'8.2em'}
        height={type === 'tab' ? '6em' :'8.2em'}
        viewBox='0 0 77 41'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='1' y='1' width='71' height='39' rx='1' stroke='#DBDBDB' strokeWidth='2' />

        <path
          d='M73 15H74C75.1046 15 76 15.8954 76 17V20.5V24C76 25.1046 75.1046 26 74 26H73'
          stroke='#DBDBDB'
          strokeWidth='2'
        />

        <rect x='3' y='3' width={`${percentage / 1.15}%`} height='35' rx='1' fill='#F5C451' />
        <text x='25' y='25' style={{fontSize: '14px'}} className='fw-bolder' fill='black'>
          {percentage}%
        </text>
      </svg>
      <div
        className='d-flex flex-column align-items-center position-absolute px-2'
        style={{bottom: type==='tab' ? '-14px' : '-10px', left: type==='tab' ? '-5px' : ''}}
      >
        <span className={`fw-bolder ${type==='tab' ? 'fs-9 w-90px' : 'fs-8'}`}>
          {text}
        </span>
        <span className={`fw-bold ${type==='tab' ? 'fs-9 w-80px' : 'fs-8'}`}>{value}kWh</span>
      </div>
    </>
  )
}

export default BatteryComponent
