import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'
import { EnergyFlowsReducer } from '../../app/pages/energyflows/redux/EnergyFlowsRedux'

export const rootReducer = combineReducers({
  energyflows: EnergyFlowsReducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
    yield all([])
  }