/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  // {
  //   lang: 'zh',
  //   name: 'Mandarin',
  //   flag: toAbsoluteUrl('/media/flags/china.svg'),
  // },
  // {
  //   lang: 'es',
  //   name: 'Spanish',
  //   flag: toAbsoluteUrl('/media/flags/spain.svg'),
  // },
  // {
  //   lang: 'ja',
  //   name: 'Japanese',
  //   flag: toAbsoluteUrl('/media/flags/japan.svg'),
  // },
  {
    lang: 'de',
    name: 'German',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  // {
  //   lang: 'fr',
  //   name: 'French',
  //   flag: toAbsoluteUrl('/media/flags/france.svg'),
  // },
]

const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <>
      <a
        href='#'
        className='btn btn-icon'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-attach='parent'
      >
        <img
          className='w-25px h-25px rounded-1'
          src={currentLanguage?.flag}
          alt='metronic'
          />
      </a>

      <div
        data-kt-menu='true'
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold fs-base w-175px py-4'
      >
        {languages.map((l) => (
          <div
            className='menu-item px-3 my-0'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </>
  )
}

export {Languages}
