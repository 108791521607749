import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import { rootReducer, rootSaga } from './RootReducer'
import {reduxBatch} from '@manaflair/redux-batch'
import {persistStore} from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()

const middleware = [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
    sagaMiddleware,
  ]

const store = configureStore({
    reducer: rootReducer,
    middleware,
    enhancers: [reduxBatch]
})

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export default store