import {FC} from 'react'
import {CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {SVGICON} from '../../../_metronic/helpers'

interface Props {
  type: number | string
  src: any
  className: string
  text?: string | undefined
  percentage: number
  color?: string
  maxVoltage?: number
  voltage?: string
}

const MainCircle: FC<Props> = ({
  type,
  src,
  className,
  text,
  percentage,
  color,
  voltage,
  maxVoltage,
}) => {
  return (
    <div
      className='card'
      style={{
        width:
          type === 1
            ? '90px'
            : type === 2
            ? '75px'
            : type === 'tab1'
            ? '75px'
            : type === 'tab2'
            ? '58px'
            : type === 'tab3'
            ? '50px'
            : '65px',
        zIndex: '99',
      }}
    >
      <CircularProgressbarWithChildren
        strokeWidth={4}
        value={percentage}
        styles={buildStyles({
          pathTransitionDuration: 0.5,
          pathColor:
            color === 'brown'
              ? `rgba(92, 64, 51, ${
                  voltage && maxVoltage ? parseInt(voltage) / maxVoltage : 66 / 100
                })`
              : `rgba(163, 198, 46, ${
                  voltage && maxVoltage ? parseInt(voltage) / maxVoltage : 66 / 100
                })`,
        })}
      >
        <div className='d-flex flex-column align-items-center' style={{width: 'fit-content'}}>
          <SVGICON src={src} className={className} />
          {type !== 3 && (
            <span
              className='fw-bolder'
              style={{
                fontSize: `${
                  type === 1
                    ? '0.819rem'
                    : type === 'tab1'
                    ? '0.7rem'
                    : type === 'tab2'
                    ? '0.66rem'
                    : '0.8rem'
                }`,
              }}
            >
              {
                // type === 1 || type === 'tab1'
                //   ? text === undefined
                //     ? ''
                //     : Math.abs(parseFloat(text)).toFixed(2) + '/' + (color === 'brown' ? 250 : 150)
                //   :
                text === undefined ? '' : Math.abs(parseFloat(text)).toFixed(2) + 'kW'
              }
            </span>
          )}
        </div>
        {/* {(type === 1 || type === 'tab1') && (
          <div className='d-flex flex-column align-items-center ' style={{width: 'fit-content'}}>
            <span
              className='fw-bolder'
              style={{
                fontSize: `${
                  type === 1
                    ? '0.819rem'
                    : type === 'tab1'
                    ? '0.7rem'
                    : type === 'tab2'
                    ? '0.66rem'
                    : '0.8rem'
                }`,
              }}
            >
              kW
            </span>
          </div>
        )} */}
      </CircularProgressbarWithChildren>
    </div>
  )
}

export default MainCircle
