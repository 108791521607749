import React, {FC, useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {useIntl} from 'react-intl'
import {EnergyFlowsLastSevenHoursConsumptionDetails} from './types/getEnergyFlowsResponseType'
import moment from 'moment'
import SimpleLoader from '../../sharedComponents/Loader/SimpleLoader'

interface Props {
  displayLastSevenDaysChartDetails: EnergyFlowsLastSevenHoursConsumptionDetails[]
  loading: boolean
}

const MixedChart: FC<Props> = ({displayLastSevenDaysChartDetails, loading}) => {
  const intl = useIntl()
  const [hours, setHours] = useState<string[]>()
  const [consumerConsumption, setConsumerConsumption] = useState<number[]>()
  const [totalGeneration, setTotalGeneration] = useState<number[]>()
  const [gridConsumption, setGridConsumption] = useState<number[]>()
  const [degreeOfSelfSufficiency, setDegreeOfSelfSufficiency] = useState<number[]>()

  useEffect(() => {
    if (displayLastSevenDaysChartDetails) {
      const hourly = displayLastSevenDaysChartDetails?.map((item) =>
        moment.utc(item.datetime).format('DD.MM.YYYY')
      )
      setHours(hourly)

      const consumer = displayLastSevenDaysChartDetails?.map((item) =>
        parseFloat(item.TotalConsumerConsumption.toFixed(2))
      )
      setConsumerConsumption(consumer)

      const total = displayLastSevenDaysChartDetails?.map((item) =>
        parseFloat(item.TotalGeneration.toFixed(2))
      )
      setTotalGeneration(total)

      const grid = displayLastSevenDaysChartDetails?.map((item) =>
        parseFloat(item.TotalGridConsumption.toFixed(2))
      )
      setGridConsumption(grid)

      const degree = displayLastSevenDaysChartDetails?.map((item) =>
        parseFloat(item.degreeOfSelfSufficiency.toFixed(2))
      )
      setDegreeOfSelfSufficiency(degree)
    }
  }, [displayLastSevenDaysChartDetails])

  const series = [
    {
      name: 'Total Consumption',
      type: 'column',
      data: consumerConsumption,
    },
    {
      name: 'Degree of Self Sufficiency',
      type: 'line',
      data: degreeOfSelfSufficiency,
    },
    {
      name: 'PV Generation',
      type: 'column',
      data: totalGeneration,
    },
    {
      name: 'Grid Consumption',
      type: 'column',
      data: gridConsumption,
    },
    
  ]

  const options = {
    chart: {
      type: 'bar',
      stacked: false,
      events: {
        //@ts-ignore
        mounted: (chart) => {
          chart.windowResizeHandler()
        },
      },
    },
    colors:['#008ffb', '#febc3a','#00e396', '#6e8192',],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 3, 1, 1],
    },
    title: {
      text: `Last 7 Days (${hours?.[0]} - ${hours?.[hours?.length - 1]})`,
      align: 'left',
      offsetX: 0,
    },
    xaxis: {
      categories: hours,
    },
    yaxis: [
      {
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#008ffb"
        },
        labels: {
          style: {
            colors: "#008ffb"
          }
        },
        // title: {
        //   text: "",
        //   style: {
        //     color: "#008ffb"
        //   }
        // }
      },
      {
        opposite: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#febc3a"
        },
        labels: {
          offsetX: -12,
          style: {
            colors: "#febc3a"
          }
        },
        title: {
          text: "Degree Of Self Sufficiency",
          style: {
            color: "#febc3a"
          }
        }
      },
      // {
      //   opposite: true,
      //   axisTicks: {
      //     show: true
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: "#26e7a6"
      //   },
      //   labels: {
      //     offsetX: -18,
      //     style: {
      //       colors: "#26e7a6"
      //     }
      //   },
      //   title: {
      //     text: "PV Generation",
      //     style: {
      //       color: "#26e7a6"
      //     }
      //   }
      // },
      // {
      //   opposite: true,
      //   axisTicks: {
      //     show: true
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: "#8494a2"
      //   },
      //   labels: {
      //     offsetX: -18,
      //     style: {
      //       colors: "#8494a2"
      //     }
      //   },
      //   title: {
      //     text: "Total Grid Consumption",
      //     style: {
      //       color: "#8494a2"
      //     }
      //   }
      // },
    ],
    //     axisTicks: {
    //       show: true,
    //     },
    //     axisBorder: {
    //       show: true,
    //       color: '#008FFB',
    //     },
    //     labels: {
    //       style: {
    //         colors: '#008FFB',
    //       },
    //     },
    //     // title: {
    //     //   style: {
    //     //     color: '#008FFB',
    //     //   }
    //     // },
    //     tooltip: {
    //       enabled: true,
    //     },
    //   },
    //   // {
    //   //   seriesName: 'TotalGridConsumption',
    //   //   opposite: true,
    //   //   axisTicks: {
    //   //     show: true,
    //   //   },
    //   //   axisBorder: {
    //   //     show: false,
    //   //     color: 'red',
    //   //   },
    //   //   labels: {
    //   //     style: {
    //   //       colors: 'red',
    //   //     },
    //   //   },
    //   //   title: {
    //   //     text: 'Degree Of Self Sufficiency',
    //   //     style: {
    //   //       color: 'red',
    //   //     },
    //   //   },
    //   // },
    // ],
    tooltip: {
      enabled:true,
      followCursor: true,
      color:'red'
      // fixed: {
      //   enabled: true,
      //   position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
      //   offsetY: 30,
      //   offsetX: 60,
      // },
    },
    legend: {
      horizontalAlign: 'center',
      offsetX: 40,
    },
  }

  return (
    <div className='card p-6 overflow-hidden'>
      {loading ? (
        <SimpleLoader height='400px'/>
      ) : (
        <>
          <h3 className='fw-bold p-2 ' style={{color: '#1E4696', fontSize: '22px'}}>
            {intl.formatMessage({id: 'MENU_ENERGYFLOWS_LAST_SEVEN_DAYS_CHART_TITLE'})}
          </h3>
          {/*// @ts-ignore */}
          <Chart options={options} series={series} type='bar' />
        </>
      )}
    </div>
  )
}

export default MixedChart
