import React, {FC} from 'react'
import './index.scss'

interface Props {
  width: string
  type?: string
}
const LineComponent: FC<Props> = ({width, type}) => {
  return (
    <div>
      <div style={{backgroundColor: '#1E4696', minWidth: width, height: '2px', position: 'relative'}}>
        <div
          id='arrowSlide2'
          style={{
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: `${type === 'tab' ? '7px solid transparent' : '10px solid transparent'}`,
            borderBottom: `${type === 'tab' ? '7px solid transparent' : '10px solid transparent'}`,
            borderLeft: `${type === 'tab' ? '7px solid #1E4696' : '10px solid #1E4696'}`,
            top: `${type === 'tab' ? '-6px' : '-9px'}`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default LineComponent
