import axios from 'axios'
import {
  EnergyFlowsHourlyConsumptionResponse,
  EnergyFlowsLastSevenHoursConsumptionResponse,
  EnergyFlowsResponseType,
} from '../types/getEnergyFlowsResponseType'

export function getEnergyFlows() {
  return axios.get<EnergyFlowsResponseType>(
    'https://api.stageback.climova.tech/api/v1/device/reading/getLatest'
  )
}

export function getHourlyConsumptionChartDetails() {
  return axios.get<EnergyFlowsHourlyConsumptionResponse>(
    'https://api.stageback.climova.tech/api/v1/device/reading/getLastEightHourValues'
  )
}

export function getLastSevenDaysConsumptionChartDetails() {
  return axios.get<EnergyFlowsLastSevenHoursConsumptionResponse>(
    'https://api.stageback.climova.tech/api/v1/device/reading/getLastSevenDayValues'
  )
}
