import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {useLayout} from '../../core'
import {MutableRefObject, useEffect, useRef} from 'react'
import {ToggleComponent} from '../../../assets/ts/components'
import Logo1 from '../../../assets/icons/Melva-Logo-mit-Subline.svg'
import Logo2 from '../../../assets/icons/Melva-Logo-ohne-Subline.svg'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarLogo = (props: PropsType) => {
  const {config} = useLayout()
  const toggleRef = useRef<HTMLDivElement>(null)

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])

  

  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
      <Link to='/energyflows'>
        {config.layoutType === 'dark-sidebar' ? (
          <div>
            <img alt='Logo' src={Logo1} className='h-45px app-sidebar-logo-default' />
          </div>
        ) : (
          <>
            <img
              alt='Logo'
              src={Logo1}
              className='h-45px app-sidebar-logo-default theme-light-show'
            />
            {/* <img
              alt='Logo'
              src={Logo1}
              className='h-25px app-sidebar-logo-default theme-dark-show'
            /> */}
          </>
        )}

        {/* <img
          alt='Logo'
          src={Logo2}
          className='app-sidebar-logo-minimize w-40px'
        /> */}
        <KTIcon iconName='double-left' className='fs-1 rotate-180 w-30px h-30px btn btn-sm btn-color-primary px-2 app-sidebar-logo-minimize' />
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          ref={toggleRef}
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-logo-default app-sidebar-toggle btn btn-icon btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 translate-middle rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          style={{left:'90%'}}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTIcon iconName='double-left' className='fs-1 rotate-180' />
        </div>
      )}
    </div>
  )
}

export {SidebarLogo}
