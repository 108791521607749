import {FC} from 'react'

interface Props {
  size: number
  color?: string
  value?: string
}
const SmallCircle: FC<Props> = ({size, color, value}) => {
  return (
    <div
      className='rounded-circle position-relative'
      style={{width: `${size}px`, height: `${size}px`, backgroundColor: color ? color : value === '0' ? 'gray' : '#A3C62E'}}
    >
      <div className='position-absolute d-flex justify-content-center align-items-center w-100' style={{left: '0%', top: '38%'}}>
        <span className={`fw-bold text-light`} style={{fontSize: size === 50 ? '9px' : '6px'}}>
          {value === undefined ? '0' : Math.abs(parseFloat(value)).toFixed(size === 50 ? 2 : 1)}
          <span style={{fontSize: size === 50 ? '8px' : '5px'}}>kW</span>
        </span>
      </div>
    </div>
  )
}

export default SmallCircle
