import clsx from 'clsx'
import {ToolbarType, useLayout} from '../../core'
import {PageTitleWrapper} from './page-title'
import moment from 'moment'
import {useLang} from '../../../i18n/Metronici18n'

const ToolbarWrapper = () => {
  const local = useLang()
  const {config, classes} = useLayout()
  if (!config.app?.toolbar?.display) {
    return null
  }

  const isPageTitleVisible = showPageTitle(
    config.app?.toolbar?.layout,
    config.app?.pageTitle?.display
  )

  return (
    <div
      id='kt_app_toolbar'
      className={clsx(
        'app-toolbar px-2 shadow-xs',
        classes.toolbar.join(' '),
        config?.app?.toolbar?.class
      )}
      style={{backgroundColor: 'white'}}
    >
      <div
        id='kt_app_toolbar_container'
        className={clsx(
          'app-container',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        {isPageTitleVisible && <PageTitleWrapper />}
        <span className='fw-bold fs-6'>
          {local === 'de'
            ? moment(moment.now()).format('DD.MM.yyyy')
            : moment(moment.now()).format('YYYY-MM-DD')}
        </span>
      </div>
    </div>
  )
}

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas']
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout)
}

export {ToolbarWrapper}
