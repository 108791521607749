import React, {FC, useEffect, useState} from 'react'
import Chart from 'react-apexcharts'
import {useIntl} from 'react-intl'
import {EnergyFlowsHourlyConsumptionDetails} from './types/getEnergyFlowsResponseType'
import moment from 'moment'
import SimpleLoader from '../../sharedComponents/Loader/SimpleLoader'

interface Props {
  displayHourlyChartDetails: EnergyFlowsHourlyConsumptionDetails[]
  loading: boolean
}

const AreaChart: FC<Props> = ({displayHourlyChartDetails, loading}) => {
  const intl = useIntl()
  const [hours, setHours] = useState<string[]>()
  const [consumerConsumption, setConsumerConsumption] = useState<number[]>()
  const [totalGeneration, setTotalGeneration] = useState<number[]>()
  const [gridConsumption, setGridConsumption] = useState<number[]>()

  useEffect(() => {
    if (displayHourlyChartDetails) {
      const hourly = displayHourlyChartDetails?.map((item) =>
        moment.utc(item.datetime).format('HH:mm')
      )
      const lastItem = hourly[hourly.length - 1]
      hourly.push((parseInt(lastItem) + 1).toString() + ':00')
      setHours(hourly)

      const consumer = displayHourlyChartDetails?.map((item) =>
        parseFloat(item.TotalConsumerConsumption.toFixed(2))
      )
      const ConsumerLastItem = consumer[consumer.length - 1]
      consumer.push(ConsumerLastItem)
      setConsumerConsumption(consumer)

      const total = displayHourlyChartDetails?.map((item) =>
        parseFloat(item.TotalGeneration.toFixed(2))
      )
      const totalLastItem = total[total.length - 1]
      total.push(totalLastItem)
      setTotalGeneration(total)

      const grid = displayHourlyChartDetails?.map((item) =>
        parseFloat(item.TotalGridConsumption.toFixed(2))
      )
      const gridLastItem = grid[grid.length - 1]
      grid.push(gridLastItem)
      setGridConsumption(grid)
    }
  }, [displayHourlyChartDetails])

  const options = {
    chart: {
      type: 'area',
      events: {
        //@ts-ignore
        mounted: (chart) => {
          chart.windowResizeHandler()
        },
      },
    },
    colors: ['#008ffb', '#00e396', '#6e8192'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: hours,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  }

  const series = [
    {
      name: 'Total Consumption',
      data: consumerConsumption,
    },
    {
      name: 'PV Generation',
      data: totalGeneration,
    },
    {
      name: 'Grid Consumption',
      data: gridConsumption,
    },
  ]

  return (
    <div className='card p-6 overflow-hidden'>
      {loading ? (
        <SimpleLoader height='400px' />
      ) : (
        <>
          <h3 className='fw-bold p-2' style={{color: '#1E4696', fontSize: '22px'}}>
            {intl.formatMessage({id: 'MENU_ENERGYFLOWS_HOURLY_CHART_TITLE'})}
          </h3>
          {/*// @ts-ignore */}
          <Chart options={options} series={series} type='area' key={series.length} />
        </>
      )}
    </div>
  )
}

export default AreaChart
