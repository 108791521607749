import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import {WithChildren} from '../helpers'
import languages from './messages/language'


const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const tempObj = {}

  Object.keys(languages).forEach((key) => {
    //@ts-ignore
    tempObj[key] = languages[key][locale]
  })

  return (
    <IntlProvider locale={locale} messages={tempObj}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
