import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {Languages} from '../../../partials/layout/header-menus/Languages'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'

const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'fs-1'

const Navbar = () => {
  const intl = useIntl()
  const {config} = useLayout()
  const {currentUser} = useAuth()

  return (
    <div className='app-navbar flex-shrink-0 px-2 gap-2' >
      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}
      {/* <div className='d-flex gap-6 '>
        <h3 className='fw-bold fs-2 position-relative d-flex' style={{color:'#1E4696'}}>{intl.formatMessage({id:'MENU_ENERGYFLOWS_OVERVIEW_TITLE'})}</h3>
        <h3 className='fw-bold fs-2 position-relative d-flex' style={{color:'#1E4696'}}>{intl.formatMessage({id:'MENU_ENERGYFLOWS_NEWS_TITLE'})}</h3>
        </div> */}
      <div className={clsx('app-navbar-item')}>
        <Languages />
      </div>

      <div className={clsx('app-navbar-item')}>
        <span className='fw-light'>{intl.formatMessage({id:'USER_USERDETAILS.HI_MESSAGE'})},</span>
        <span className='fw-bold'>Alex</span>
      </div>

      <div className={clsx('app-navbar-item')}>
        {currentUser && currentUser?.pic ? (
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <img src={currentUser?.pic} alt='profile' />
          </div>
        ) : (
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className='symbol-label text-success bg-light-success fs-4'>
              {('Alex' || ' ')[0]}
            </div>
          </div>
        )}
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
