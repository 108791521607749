export default {
    'MENU.DASHBOARD':{
        en:'Dashboard',
        de:'Startseite'
    },
    'MENU.ENERGYFLOWS':{
        en:'Energy Flows',
        de:'Energieflusse'
    },
    'USER_USERDETAILS.HI_MESSAGE':{
        en:'Hi',
        de:'Hallo'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_CONSUMER:{
        en:'Consumer',
        de:'Verbraúcher'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS:{
        en:'Charging Stations',
        de:'Ladesäulen'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_AC_CHARGING_STATIONS:{
        en:'AC-Charging Stations',
        de:'AC-Ladesäulen'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_DC_CHARGING_STATIONS:{
        en:'DC-Charging Stations',
        de:'DC-Ladesäulen'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_BATTERY_BUFFERED_FAST_CHARGING_STATION:{
        en:'Battery buffered fast charging station',
        de:''
    },
    ENERGYFLOWS_ENERGYINFO_CHARGING_STATIONS_FREE_TEXT:{
        en:'free',
        de:'frei'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_PV_SYSTEM:{
        en:'PV System',
        de:'PV Anlage'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_CURRENT:{
        en:'Current',
        de:'Aktuell'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_NETWORK:{
        en:'Grid',
        de:'Nétz'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_EXTERNAL_STORAGE:{
        en:'External Storage',
        de:'Externer Speicher'
    },
    MENU_ENERGYFLOWS_ENERGYINFO_ENERGY_STORAGE:{
        en:'Energy Storage',
        de:'Energiespeicher'
    },
    MENU_ENERGYFLOWS_LAST_SEVEN_DAYS_CHART_TITLE:{
        en:'Past Week',
        de:''
    },
    MENU_ENERGYFLOWS_HOURLY_CHART_TITLE:{
        en:'Hourly',
        de:''
    },
    MENU_ENERGYFLOWS_OVERVIEW_TITLE:{
        en:'Overview',
        de:'Übersicht'
    },
    MENU_ENERGYFLOWS_NEWS_TITLE:{
        en:'News',
        de:'Nachricht'
    },
    AUTHENTICATION_LOGIN_TITLE:{
        en:'Sign In',
        de:''
    },
    AUTHENTICATION_LOGIN_EMAIL_INPUT_FIELD_TITLE:{
        en:'Email',
        de:''
    },
    AUTHENTICATION_LOGIN_PASSWORD_INPUT_FIELD_TITLE:{
        en:'Password',
        de:''
    },
    AUTHENTICATION_LOGIN_FORGOT_PASSWORD_LINK_TEXT:{
        en:'Forgot Password ?',
        de:''
    },
    AUTHENTICATION_LOGIN_SUBMIT_BUTTON_TEXT:{
        en:'Continue',
        de:''
    },
    AUTHENTICATION_LOGIN_SUBMIT_BUTTON_LOADING_TEXT:{
        en:'Please wait...',
        de:''
    },
    AUTHENTICATION_LOGIN_SIGNUP_MESSAGE_TEXT:{
        en:'Not a Member yet?',
        de:''
    },
    AUTHENTICATION_LOGIN_SIGNUP_LINK_TEXT:{
        en:'Sign Up',
        de:''
    },
    AUTHENTICATION_SIGNUP_TITLE:{
        en:'Sign Up',
        de:''
    },
    AUTHENTICATION_SIGNUP_FIRSTNAME_INPUT_FIELD_TITLE:{
        en:'First Name',
        de:''
    },
    AUTHENTICATION_SIGNUP_LASTNAME_INPUT_FIELD_TITLE:{
        en:'Last Name',
        de:''
    },
    AUTHENTICATION_SIGNUP_EMAIL_INPUT_FIELD_TITLE:{
        en:'Email',
        de:''
    },
    AUTHENTICATION_SIGNUP_PASSWORD_INPUT_FIELD_TITLE:{
        en:'Password',
        de:''
    },
    AUTHENTICATION_SIGNUP_PASSWORD_VALIDATION_TEXT:{
        en:'Use 8 or more characters with a mix of letters, numbers & symbols.',
        de:''
    },
    AUTHENTICATION_SIGNUP_CONFIRM_PASSWORD_INPUT_FIELD_TITLE:{
        en:'Confirm Password',
        de:''
    },
    AUTHENTICATION_SIGNUP_ACCEPT_TERMS_TEXT:{
        en:'I Accept the',
        de:''
    },
    AUTHENTICATION_SIGNUP_TERMS_TEXT:{
        en:'Terms',
        de:''
    },
    AUTHENTICATION_SIGNUP_SUBMIT_BUTTON:{
        en:'Submit',
        de:''
    },
    AUTHENTICATION_SIGNUP_SUBMIT_BUTTON_LOADING_TEXT:{
        en:'Please wait...',
        de:''
    },
    AUTHENTICATION_SIGNUP_CANCEL_BUTTON:{
        en:'Cancel',
        de:''
    },
    AUTHENTICATION_FORGOT_PASSWORD_TITLE:{
        en:'Forgot Password ?',
        de:''
    },
    AUTHENTICATION_FORGOT_PASSWORD_MESSAGE_TEXT:{
        en:'Enter your email to reset your password.',
        de:''
    },
    AUTHENTICATION_FORGOT_PASSWORD_EMAIL_INPUT_FIELD_TITLE:{
        en:'Email',
        de:''
    },
    AUTHENTICATION_FORGOT_PASSWORD_SUBMIT_BUTTON:{
        en:'Submit',
        de:''
    },
    AUTHENTICATION_FORGOT_PASSWORD_SUBMIT_BUTTON_LOADING_MESSAGE:{
        en:'Please wait...',
        de:''
    },
    AUTHENTICATION_FORGOT_PASSWORD_CANCEL_BUTTON:{
        en:'Cancel',
        de:''
    },
    AUTHENTICATION_FORGOT_PASSWORD_ERROR_MESSAGE_TEXT:{
        en:'Sorry, looks like there are some errors detected, please try again.',
        de:''
    },
    AUTHENTICATION_FORGOT_PASSWORD_SUCCESS_MESSAGE_TEXT:{
        en:'Sent password reset. Please check your email',
        de:''
    },
    USER_USER_DETAILS_PROFILE_TITLE:{
        en:'My Profile',
        de:''
    },
    USER_USER_DETAILS_ACCOUNT_SETTINGS_TITLE:{
        en:'Account Settings',
        de:''
    },
    USER_SIGN_OUT_TITLE:{
        en:'Sign Out',
        de:''
    },
}