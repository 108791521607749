import {FC} from 'react'
import './index.scss'

interface Props {
  width: string
  type?: string
  color?: string
  value?: number
}
const ArrowComponent: FC<Props> = ({width, type, color, value}) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: color ? color : value === 0 ? 'gray' : '#1E4696',
          width: width,
          height: '2px',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '0',
            height: '0',
            borderTop: `${type === 'tab' ? '7px solid transparent' : '10px solid transparent'}`,
            borderBottom: `${type === 'tab' ? '7px solid transparent' : '10px solid transparent'}`,
            borderLeft: `${
              type === 'tab'
                ? `7px solid ${color ? color : '#1E4696'}`
                : `10px solid ${color ? color : '#1E4696'}`
            }`,
            top: `${type === 'tab' ? '-6px' : '-9px'}`,
            right: '-1px',
          }}
        ></div>
        {value == 0 || value == undefined ? null : (
          <div
            id='arrowSlide'
            style={{
              position: 'absolute',
              width: '0',
              height: '0',
              borderTop: `${type === 'tab' ? '7px solid transparent' : '10px solid transparent'}`,
              borderBottom: `${
                type === 'tab' ? '7px solid transparent' : '10px solid transparent'
              }`,
              borderLeft: `${
                type === 'tab'
                  ? `7px solid ${color ? color : '#1E4696'}`
                  : `10px solid ${color ? color : '#1E4696'}`
              }`,
              top: `${type === 'tab' ? '-6px' : '-9px'}`,
            }}
          ></div>
        )}
      </div>
    </div>
  )
}

export default ArrowComponent
