/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import { useIntl } from 'react-intl'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const intl = useIntl()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {currentUser && currentUser?.pic ? (
              <img src={currentUser?.pic} alt='profile' />
            ) : (
              <div className='symbol-label bg-light-success text-success fs-2'>
                {('Alex' || ' ')[0]}
              </div>
            )}
          </div>

          <div className='d-flex flex-column gap-1'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {'Alex'} {'Alex'}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
            {/* <button onClick={logout} className='btn btn-sm text-primary bg-primary bg-opacity-25 py-1 fw-bold fs-7' style={{width:'fit-content'}}>
                  Sign Out
            </button> */}
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          {intl.formatMessage({id:'USER_USER_DETAILS_PROFILE_TITLE'})}
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
        {intl.formatMessage({id:'USER_USER_DETAILS_ACCOUNT_SETTINGS_TITLE'})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
        {intl.formatMessage({id:'USER_SIGN_OUT_TITLE'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
