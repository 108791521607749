import React, {FC} from 'react'
import {SVGICON} from '../../../_metronic/helpers'
import MainCircle from './MainCircle'
import SolarPanel from '../../../_metronic/assets/icons/solarpanel.svg'

interface Props {
  src: any
  value: string | undefined
  percentage: number
  title: string | undefined
  solarpercent: number
  circletype: string | number
}

const EnergyFlowCard: FC<Props> = ({src, value, percentage, title, solarpercent, circletype}) => {
  return (
    <div className=' d-flex align-items-center gap-4'>
      <div className=''>
        <MainCircle
          type={circletype}
          src={src}
          className='svg-icon-2x'
          text={value}
          percentage={percentage}
        />
      </div>
      <div>
        <span className='fw-bolder fs-8'>{title}</span>
        <div className='d-flex gap-1 align-items-center'>
          <SVGICON src={SolarPanel} className='svg-icon-sm' />
          <span className='fw-bold fs-9 text-success'>{solarpercent}%</span>
        </div>
      </div>
    </div>
  )
}

export default EnergyFlowCard
