import {Action} from '@reduxjs/toolkit'
import { EnergyFlowsHourlyConsumptionDetails, EnergyFlowsHourlyConsumptionResponse, EnergyFlowsType } from '../types/getEnergyFlowsResponseType'

export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

export const actionTypes = {
    SET_ENERGY_FLOWS : 'SET_ENERGY_FLOWS',
    SET_HOURLY_CONSUMPTION_DETAILS: 'SET_HOURLY_CONSUMPTION_DETAILS'
}

export interface EnergyFlowsInitState {
    energyflows: EnergyFlowsType
    hourlyConsumptionDetails: EnergyFlowsHourlyConsumptionResponse
}

const initialState: EnergyFlowsInitState = {
    energyflows: {} as EnergyFlowsType,
    hourlyConsumptionDetails: {} as EnergyFlowsHourlyConsumptionResponse
  }

export const EnergyFlowsReducer = (state = initialState, action: ActionWithPayload<EnergyFlowsInitState>) => {
    switch (action.type) {
      case actionTypes.SET_ENERGY_FLOWS: {
        const energyflows = action.payload || []
        return {...state, energyflows: energyflows}
      }
      case actionTypes.SET_HOURLY_CONSUMPTION_DETAILS: {
        const hourlyConsumptionDetails = action.payload || []
        return {...state, details: hourlyConsumptionDetails}
      }
      default:
        return state
    }
  }

  export const actions = {
    setEnergyFlows: (energyflows: EnergyFlowsType) => ({
      type: actionTypes.SET_ENERGY_FLOWS,
      payload: energyflows,
    }),
    setHourlyConsumptionDetails: (hourlyConsumptionDetails: EnergyFlowsHourlyConsumptionResponse) => ({
      type: actionTypes.SET_HOURLY_CONSUMPTION_DETAILS,
      payload: hourlyConsumptionDetails,
    }),
  }