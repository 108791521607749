import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../_metronic/layout/core'
import AreaChart from './AreaChart'
import EnergyFlowsMain from './EnergyFlowsMain'
import MixedChart from './MixedChart'
import {
  getEnergyFlows,
  getHourlyConsumptionChartDetails,
  getLastSevenDaysConsumptionChartDetails,
} from './redux/EnergyFlowsAPI'
import {actions} from './redux/EnergyFlowsRedux'

const EnergyFlowsContent: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [displayEnergyFlows, setDisplayEnergyFlows] = useState<any>()
  const [APIloading, setAPILoading] = useState(false)
  const [hourlyChartLoading, setHourlyChartLoading] = useState(false)
  const [lastSevenChartLoading, setLastSevenChartLoading] = useState(false)
  const [displayHourlyChartDetails, setDisplayHourlyChartDetails] = useState<any>()
  const [displayLastSevenDaysChartDetails, setDisplayLastSevenDaysChartDetails] = useState<any>()

  const getEnergyFlowsAPI = () => {
    // setAPILoading(true)
    getEnergyFlows()
      .then(({data}) => {
        dispatch(actions.setEnergyFlows(data))
        setDisplayEnergyFlows(data)
      })
      .finally(() => {
        // setAPILoading(false)
      })
  }

  const getHourlyConsumptionChartAPI = () => {
    // setHourlyChartLoading(true)
    getHourlyConsumptionChartDetails()
      .then(({data}) => {
        dispatch(actions.setHourlyConsumptionDetails(data))
        setDisplayHourlyChartDetails(data)
      })
      .finally(() => {
        // setHourlyChartLoading(false)
      })
  }

  const getLastSevenDaysConsumptionChartAPI = () => {
    // setLastSevenChartLoading(true)
    getLastSevenDaysConsumptionChartDetails()
      .then(({data}) => {
        dispatch(actions.setHourlyConsumptionDetails(data))
        setDisplayLastSevenDaysChartDetails(data)
      })
      .finally(() => {
        // setLastSevenChartLoading(false)
      })
  }

  useEffect(() => {
    getEnergyFlowsAPI()
    getHourlyConsumptionChartAPI()
    getLastSevenDaysConsumptionChartAPI()
    //calls for every 10 seconds
    setInterval(() => {
      getEnergyFlowsAPI()
    }, 10000)
    //calls for every one hour
    setInterval(() => {
      getHourlyConsumptionChartAPI()
      getLastSevenDaysConsumptionChartAPI()
    }, 60 * 60 * 1000)
  }, [])

  return (
    <>
      <div className='p-2' style={{maxWidth: '1440px', margin: '0 auto'}}>
        <h2 className='fw-bold ' style={{color: '#1E4696', fontSize: '30px'}}>
          {intl.formatMessage({id: 'MENU.ENERGYFLOWS'})}
        </h2>
        {/* <SVGICON src={MCircle} className={'svg-icon-4x'} /> */}
        {/* <div className='px-2 circleParent'>
          <div className='circleText'>We are Hiring!</div>
        </div> */}
        <div className='p-2 pb-6'>
          <h3 className='fw-bold fs-2' style={{color: '#A3C62E'}}>
            {displayEnergyFlows?.name?.charAt(0).toUpperCase() + displayEnergyFlows?.name?.slice(1)}
          </h3>
          <div className='d-flex gap-3'>
            <span className=''>{moment(moment.now()).format('llll')}</span>
            <span>
              <i className='bi bi-clouds fs-4' style={{color: '#1E4696'}}></i> 14 °C
            </span>
          </div>
        </div>
      </div>
      <div className='row g-5 p-2' style={{maxWidth: '1440px', margin: '0 auto'}}>
        {/* <div className='col-md-12 position-relative'>
          <EnergyFlowsMain />
        </div> */}
        <div className='col-md-12'>
          <EnergyFlowsMain displayEnergyFlows={displayEnergyFlows} loading={APIloading} />
        </div>
        <div className='col-lg-12 col-xl-6'>
          <AreaChart
            displayHourlyChartDetails={displayHourlyChartDetails}
            loading={hourlyChartLoading}
          />
        </div>
        <div className='col-lg-12 col-xl-6'>
          <MixedChart
            displayLastSevenDaysChartDetails={displayLastSevenDaysChartDetails}
            loading={lastSevenChartLoading}
          />
        </div>
      </div>
    </>
  )
}

const EnergyFlowsPage = () => {
  const intl = useIntl()
  return (
    <div>
      <Helmet>
        <title>Melva-Energy Flows</title>
      </Helmet>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ENERGYFLOWS'})}</PageTitle>
      <EnergyFlowsContent />
    </div>
  )
}

export default EnergyFlowsPage
